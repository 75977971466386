<template>
    <Dialog v-model:visible="show" modal dismissableMask class="mandp-product-detail-dialog" :style="{ width: '30vw' }" :breakpoints="{ '960px': '75vw',
    '641px': '90vw'}" :draggable="false">

        <div class="mandp-product-card" :class="{ 'drink' : is_drink }">

            <div v-if="product.product_img !== ''" class="mandp-product-card--image">
                <img :src="product.product_img" alt="">
            </div>

            <div class="mandp-product-card--body">
                <div class="grid">
                    <div style="padding-bottom: 0" class="col">
                        <p class="mandp-product-card--name">
                            {{ product.name }}
                            <span v-if="product.weight !== '' && product.weight !== null && ! product.has_price_variables"
                                  class="mandp-product-card--weight">
                            {{ product.weight }} {{ product.weight_type }}
                        </span>
                        </p>
                    </div>
                    <div style="padding-bottom: 0" class="col-fixed">
                        <p v-if="!product.has_price_variables" class="mandp-product-card--price">
                            $ {{ product.price }}
                        </p>
                    </div>
                </div>

                <p v-if="product.description" class="mandp-product-card--description">{{ product.description }}</p>

                <template v-if="product.has_price_variables">
                    <div style="margin-top: 15px" class="grid">
                        <div class="col-6">
                            <div class="mandp-product-card--price-variable">
                                <p v-if="product.weight !== '' && product.weight !== null" >
                                </p>
                                <p class="mandp-product-card--price">
                                    $ {{ product.price }}
                                    <span v-if="product.weight" class="mandp-product-card--weight">
                                    {{ product.weight }} {{ product.weight_type || '' }}
                                </span>
                                </p>
                            </div>
                        </div>
                        <div class="col-6" v-for="(price, i) in product.prices" :key="`price_${product.id}_${i}`">
                            <p class="mandp-product-card--price-name">
                                {{ price.name }}
                            </p>
                            <div class="mandp-product-card--price-variable">
                                <p class="mandp-product-card--price">
                                    $ {{ price.price }}
                                    <span v-if="price.weight" class="mandp-product-card--weight">
                                    {{ price.weight }} {{ price.weight_type ||  '' }}
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="product.promos.length > 0">
                    <div class="mandp-product-card--promos">
                        <h4>Promociones</h4>
                        <div class="flex flex-wrap justify-content-center">
                            <div class="mandp-product-card--promos--item" v-for="(promo, i) in product.promos" :key="`promo_${product.id}_${i}`">
                                <template v-if=" promo.type === 'price' ">
                                    <p>{{ promo.data_1 }} X $ {{ promo.data_2 }}</p>
                                </template>
                                <template v-if=" promo.type === 'quantity' ">
                                    <p>{{ promo.data_1 }} X {{ promo.data_2 }}</p>
                                </template>
                                <template v-if=" promo.type === 'combo' ">
                                    <p>{{ promo.data_1 }} X $ {{ promo.data_2 }}</p>
                                </template>
                                <template v-if=" promo.type === 'other' ">
                                    <p>{{ promo.data_1 }}</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog'

    export default {
        data(){
            return{
                show: false,
                product: null,
                is_drink: false,
            }
        },
        mounted() {
            this.$emitter.on('mandp--product-details', (params) => {
                this.product = params.product
                this.is_drink = params.is_drink || false
                this.show = true
            } )
        },
        components: {
            Dialog,
        }
    }
</script>