<template>
    <div class="mandp--entr nbg">
        <div class="container">
            <h1>{{ section.name }}</h1>
            <div class="mandp--platf--riz">
                <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMED-1%2Fassets%2Fmed-1-rizg-removebg-preview.png"
                     alt="">
            </div>
            <div class="mandp--entr--products">
                <template v-if=" section.grid_type === 'list' ">
                    <ProductDrink v-for="product in section.products"
                                  :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                </template>

                <div v-else class="row products-container justify-content-center">
                    <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                         :key="`menu_product_${product.id}`">
                        <ProductDrink :theme_code="theme_code" :product="product" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            ProductDrink,
        }
    }
</script>