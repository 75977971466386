<template>
    <div class="mandp-product-list-item" @click="onWatchProductDetails">
        <div class="grid align-items-center">
            <div v-if="product.product_img !== ''" class="col-fixed d-none d-sm-flex d-md-flex d-lg-flex" >
                <div class="mandp-product-list-item--image">
                    <img :src="product.product_img" alt="">
                </div>
            </div>
            <div class="col">
                <p class="mandp-product-card--name">
                    {{ product.name }}
                    <span v-if="product.weight !== '' && product.weight !== null && ! product.has_price_variables"
                          class="mandp-product-card--weight">
                                {{ product.weight }} {{ product.weight_type }}
                            </span>
                </p>
                <p v-if="product.description" class="mandp-product-card--description">{{ product.description }}</p>
            </div>
            <div style="padding-bottom: 0" class="col-fixed">
                <p class="mandp-product-card--price">
                    $ {{ product.price }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            product: { required: true, type: [ Object, Array ] },
        },
        methods: {
            onWatchProductDetails(){
                this.$emitter.emit('mandp--product-details', { product: this.product })
            }
        }
    }
</script>