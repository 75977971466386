import { createRouter, createWebHistory } from 'vue-router'

import NotFound from './views/404NotFound'
import MenuViewer from './views/MenuViewer'
import MenuPdfViewer from './views/MenuPDFViewer'
import MenuURLViewer from "./views/MenuURLViewer";
import BeersMenuViewer from "@/views/BeersMenuViewer.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: '<h1>Default</h1>'
        },
        {
            path: '/menu/:uid',
            component: MenuViewer,
        },
        {
            path: '/pdf/:uid',
            component: MenuPdfViewer,
        },
        {
            path: '/url/:uid',
            component: MenuURLViewer,
        },
        {
            path: '/beers/:uid',
            component: BeersMenuViewer,
        },
        {
            path: '/404',
            component: NotFound,
        }
    ]
})

export default router
