<template>
    <div class="mandp-section-one--header">
        <h1>{{ section.name }}</h1>
    </div>
    <div class="mandp-section-one--line-one"></div>
    <div class="mandp-section-one--line-two"></div>
    <div class="mandp-section-one--products">
        <div class="container">

            <MenuProductFoodRender
                :theme_code="theme_code"
                :section="section"
            />

        </div>
    </div>

</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>