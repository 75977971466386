<template>
    <div class="mandp-section-one">
        <div class="container">
            <div class="section-one">
                <div class="mandp-section-one--header">
                    <h1>{{ section.name }}</h1>
                </div>
                <div class="mandp-section-one--products">

                    <MenuProductFoodRender
                        :theme_code="theme_code"
                        :section="section"
                    />

                </div>
                <div class="mandp-section-one--bottom">
                    <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FCER-PREM-1%2Fassets%2Fmandp-nachos.png" alt="">
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>