<template>
    <div class="mandp-bohemia-section">
        <div class="container">
            <center>
                <img class="mandp-bohemia-section--logo animate__animated" data-animation="animate__slideInDown" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/bohemia-section-logo.png" alt="">
            </center>


            <div class="grid justify-content-center" style="margin-top: -50px">
                <div v-if=" Pilsner " class="col-6 animate__animated" data-animation="animate__slideInLeft">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container white">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Bohemia_Botella_Pilsner_Nueva-bottle.png" alt="">
                            <div class="mandp-beers-card--info orange">
                                <p>Bohemia Pilsner</p>
                                <p style="color: #5a2c13"><small>355ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ Pilsner?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if=" Vienna " class="col-6 animate__animated" data-animation="animate__slideInRight">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container white">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Bohemia_Botella_Vienna-bottle.png" alt="">
                            <div class="mandp-beers-card--info orange">
                                <p>Bohemia Vienna</p>
                                <p style="color: #5a2c13"><small>355ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ Vienna?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if=" Weizen " class="col-6 animate__animated" data-animation="animate__slideInLeft">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container white">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Bohemia_Botella_Weizen_Nueva-bottle.png" alt="">
                            <div class="mandp-beers-card--info orange">
                                <p>Bohemia Weizen</p>
                                <p style="color: #5a2c13"><small>355ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ Weizen?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if=" Cristal " class="col-6 animate__animated" data-animation="animate__slideInRight">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container white">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Bohemia_Botella_Cristal_Nueva-bottle.png" alt="">
                            <div class="mandp-beers-card--info orange">
                                <p>Bohemia Cristal</p>
                                <p style="color: #5a2c13"><small>355ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ Cristal?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if=" menu_settings.bohemia_imgs ">
        <img class="mandp-bohemia-section--info-img animate__animated" data-animation="animate__slideInLeft" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/bohemia-vienna-back.png" alt="">
        <img class="mandp-bohemia-section--info-img animate__animated" data-animation="animate__slideInRight" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/bohemia-cristal-back.png" alt="">
        <img class="mandp-bohemia-section--info-img animate__animated" data-animation="animate__slideInLeft" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/bohemia-pilsner-back.png" alt="">
        <img class="mandp-bohemia-section--info-img animate__animated" data-animation="animate__slideInRight" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/bohemia-weizen-back.png" alt="">
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array },
            menu_settings: { required: true, type: Object }
        },
        computed: {
            Pilsner(){
                return this.products.filter(p => p.name === 'Bohemia Plisner Clara')[0]
            },
            Vienna(){
                return this.products.filter(p => p.name === 'Bohemia Vienna Oscura')[0]
            },
            Weizen(){
                return this.products.filter(p => p.name === 'Bohemia Weizen')[0]
            },
            Cristal(){
                return this.products.filter(p => p.name === 'Bohemia Cristal')[0]
            },
        }
    }
</script>
