<template>
    <div class="mandp-sider-section">
        <img class="mandp-sider-section--logo" src="https://strongbow.mx/Logo_Strongbow.png">
        <div class="container">
            <div class="grid justify-content-center">
                <div v-if=" GoldApple " class="col-6 animate__animated" data-animation="animate__slideInLeft">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container grey">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.digitaloceanspaces.com/image-bank/PVacOVOYukikyE2BUP0mbLfasqrBKwrQWWE9JIWE.png" alt="">
                            <div class="mandp-beers-card--info red">
                                <p>Gold Apple</p>
                                <p><small>330ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ GoldApple?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if=" Honey " class="col-6 animate__animated" data-animation="animate__slideInLeft">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container grey">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/honey-bottle.png" alt="">
                            <div class="mandp-beers-card--info red">
                                <p>Strongbow Honey</p>
                                <p><small>330ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ Honey?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if=" RoseApple " class="col-6 animate__animated" data-animation="animate__slideInUp">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container grey">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Rose-Apple-bottle.png" alt="">
                            <div class="mandp-beers-card--info red">
                                <p>Strongbow Rose Apple</p>
                                <p><small>330ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ RoseApple?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if=" RedBerries " class="col-6 animate__animated" data-animation="animate__slideInRight">
                    <div class="mandp-beers-card">
                        <div class="mandp-beers-card--container grey">
                            <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Red-Berries-bottle.png" alt="">
                            <div class="mandp-beers-card--info red">
                                <p>Strongbow Red Berries</p>
                                <p><small>330ml</small></p>
                            </div>
                            <div class="mandp-beers-card--price">
                                <p>${{ RedBerries?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array }
        },
        computed: {
            Honey(){
                return this.products.filter(p => p.name === 'Strongbow Honey')[0]
            },
            RedBerries(){
                return this.products.filter(p => p.name === 'Strongbow Red Berries')[0]
            },
            RoseApple(){
                return this.products.filter(p => p.name === 'Strongbow Rose Apple')[0]
            },
            GoldApple(){
                return this.products.filter(p => p.name === 'Strongbow Gold Apple')[0]
            }
        }
    }
</script>
