<template>
    <div class="mandp-section-one">
        <h1>({{ section.name }})</h1>
    </div>
    <div class="container ">

        <template v-if=" section.grid_type === 'list' ">
            <ProductDrink v-for="product in section.products"
                          :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
        </template>

        <div v-else class="mandp-section-one--container">
            <div class="products-container row align-items-center justify-content-center">

                <div class="product-card gx-4 gy-4 col-sm-12 col-md-6 col-lg-3" v-for="product in section.products"
                     :key="`menu_product_${product.id}`">
                    <ProductDrink dark_card :theme_code="theme_code" :product="product" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            ProductDrink,
        }
    }
</script>