<template>
    <div ref="container" :class="`mandp-menu-beers-promos`">
        <div class="container">
            <h1 style="padding-top: 125px" class="mandp-section-name animate__animated animate__delay-1s" data-animation="animate__slideInDown">PROMOCIONES</h1>

            <div ref="content" class="mandp-menu-beers-promos--container">

                <div class="mandp-menu-beers-promos--container--carousel">
                    <Carousel :value=" promos " :num-visible="1" circular :autoplayInterval="5000" :show-indicators="false">
                        <template #item="slotProps">
                            <component :is=" slotProps.data.theme_id " :data=" slotProps.data " />
                        </template>

                    </Carousel>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Carousel from "primevue/carousel"
    import PromoIndex from "@/components/Promos/PromoIndex.vue"

    import Promo01 from "@/components/Promos/Promo01.vue"
    import Promo02 from "@/components/Promos/Promo02.vue"
    import Promo03 from "@/components/Promos/Promo03.vue"
    import Promo04 from "@/components/Promos/Promo04.vue"

    export default {
        props: {
            promos: { required: true, type: Array, default(){ return [] } },
            hnk_promos: { required: true, type: Array, default(){ return [] } },
        },

        components: {
            Carousel,
            PromoIndex,
            'promo-01': Promo01,
            'promo-02': Promo02,
            'promo-03': Promo03,
            'promo-04': Promo04,
        },
        mounted() {
            //let container_height = this.$refs.container.offsetHeight
            //window.$( this.$refs.content ).css({ 'height': `${container_height-100}px` })
        }
    }
</script>
