<template>
    <template v-if="!loading">

        <div class="menu-body">
            <div class="menu-content">
                <BeersHeader :cc="menu.cc" />

                <PromoSection v-if=" HasPromos " :promos=" menu.promotions " :hnk_promos=" menu.hnk_promos " />

                <NonAlcoholicSection v-if=" NonAlcoholicProducts.length > 0 " :products="NonAlcoholicProducts" />

                <HeinekenSection v-if=" PremiumProducts.length > 0 " :products="PremiumProducts" />

                <BohemiaSection v-if=" BohemiaProducts.length > 0 " :products="BohemiaProducts" :menu_settings=" menu.settings " />

                <BeersSection v-if=" BeersProducts.length > 0 " :products="BeersProducts" />

                <BigBeersSection v-if=" ( BigCBeersProducts.length + BigBeersProducts.length ) > 0 " :products="BigCBeersProducts" :cproducts="BigBeersProducts" />

                <SidersSection v-if=" CidersProducts.length > 0 " :products="CidersProducts" />
            </div>
        </div>

        <ConfirmDialog />

    </template>

</template>

<script>
    import '@/assets/css/beers-menu.scss'

    import ConfirmDialog from 'primevue/confirmdialog'

    import BeersHeader from "./components/BeersHeader.vue"
    import PromoSection from "./components/PromoSection.vue"
    import NonAlcoholicSection from "./components/NonAlcoholicSection.vue"
    import HeinekenSection from "@/components/BeersMenu/components/HeinekenSection.vue";
    import BeersSection from "@/components/BeersMenu/components/BeersSection.vue";
    import SidersSection from "@/components/BeersMenu/components/SidersSection.vue";
    import BohemiaSection from "@/components/BeersMenu/components/BohemiaSection.vue";
    import BigBeersSection from "@/components/BeersMenu/components/BigBeersSection.vue";

    export default {
        data(){
            return {
                menu: null,
                hnk_promos: [],
                loading: true,
            }
        },
        computed: {
            HasPromos(){
                let promos_length = this.menu?.promotions?.length || 0
                let hnk_promos_length = this.menu?.hnk_promos?.length|| 0
                return ( promos_length + hnk_promos_length ) > 0 || false
            },
            NonAlcoholicProducts(){
                return this.menu.sections.filter(s => s.name === 'Sin alcohol')[0]?.products || []
            },
            PremiumProducts(){
                return this.menu.sections.filter(s => s.name === 'Premium')[0]?.products || []
            },
            BeersProducts(){
                return this.menu.sections.filter(s => s.name === "Cervezas")[0]?.products || []
            },
            BigCBeersProducts(){
                return this.menu.sections.filter(s => s.name === "Caguamones")[0]?.products || []
            },
            BigBeersProducts(){
                return this.menu.sections.filter(s => s.name === "Caguamas")[0]?.products || []
            },
            CidersProducts(){
                return this.menu.sections.filter(s => s.name === "Sidras")[0]?.products || []
            },
            BohemiaProducts(){
                return this.menu.sections.filter(s => s.name === "Clan de la malta")[0]?.products || []
            },
        },
        methods: {
            fetchMenu(){
                window.axios.post(`${this.$global.API_URL}/get-menu-beers/${this.$route.params.uid}`, {
                    'analytic_source': this.$route.query.utm_source || 'web',
                    'redirected' : this.$route.query.redirected || undefined,
                })
                    .then(response => {
                        if( response.data.success ){
                            this.menu = response.data.menu
                            this.hnk_promos = response.data.hnk_promotions
                            this.loading = false

                            // Set page title to CC name
                            document.title = this.menu.cc.name

                            let self = this
                            setTimeout(() => {
                                self.$emitter.emit('mandp--global-loading', false)
                            }, 1000)

                        }
                    })
                    .catch(error => {
                        console.log(error)
                        if( error.response.status === 404 ) this.$router.replace('/404')
                    })
            }
        },
        mounted() {
            this.fetchMenu()
        },
        components: {
            BigBeersSection,
            ConfirmDialog,
            BohemiaSection,
            SidersSection,
            BeersSection,
            HeinekenSection,
            BeersHeader,
            PromoSection,
            NonAlcoholicSection,
        }
    }
</script>
