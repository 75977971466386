<template>
    <div class="mandp-beers-promo-card--container">

        <div class="mandp-beers-promo-card theme02">

            <img class="mandp-beers-promo-card--front" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/promo-theme-04-front.png" alt="">

            <div class="mandp-theme02-loop--container">

                <div class="mandp-theme02-loop-text">
                    <div class="mandp-theme02-loop-text--inner">
                        <span v-for="i in 5" :key="`text1_${i}`">{{ data.text1 }}</span>
                    </div>
                </div>

                <div class="mandp-theme02-loop-text border">
                    <div class="mandp-theme02-loop-text--inner">
                        <span v-for="i in 10" :key="`text2_${i}`">{{ data.text2 }}</span>
                    </div>
                </div>

                <div class="mandp-theme02-loop-text">
                    <div class="mandp-theme02-loop-text--inner">
                        <span v-for="i in 5" :key="`text1_1_${i}`">{{ data.text1 }}</span>
                    </div>
                </div>

            </div>

            <div class="mandp-theme02-footer">
                <p>{{ data.text3 }}</p>
            </div>
        </div>

        <div v-if=" data.text4 && data.text4 !== 'null' " class="mandp-beers-promo-card--footer">
            <p>{{ data.text4 }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'promo-02',
        props: {
            data: { required: true, type: Object },
        },
    }
</script>

