<template>
    <template v-if=" $parent.section.grid_type === 'full-card' ">
        <FullCard :theme_code="theme_code" :product="product" />
    </template>

    <template v-else-if=" $parent.section.grid_type === 'small-card' ">
        <CompactCard :theme_code="theme_code" :product="product" />
    </template>

    <template v-else>
        <ListCard :product="product" />
    </template>
</template>

<script>
    import ListCard from "@/components/General/ProductsGridTypes/Drinks/ListCard"
    import FullCard from "@/components/General/ProductsGridTypes/Drinks/FullCard"
    import CompactCard from "@/components/General/ProductsGridTypes/Drinks/CompactCard"
export default {
    props: {
        product: { required: true, type: [ Object, Array ] },
        theme_code: { required: true, type: String },
        dark_card: { required: false, type: Boolean, default: false },
    },
    components: {
        ListCard,
        FullCard,
        CompactCard,
    }
}
</script>