<template>
    <div class="mandp-section-six-image">
        <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FCER-PREM-2%2Fassets%2Fcer-prem-2-cerv.png" alt="">
    </div>

    <div class="container mandp-section-six">
        <div class="mandp-section-six-header">
            <h1>{{ section.name }}</h1>
        </div>
        <div class="mandp-section-six-products">

            <template v-if=" section.grid_type === 'list' ">
                <ProductDrink v-for="product in section.products"
                              :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
            </template>

            <div v-else class="products-container row justify-content-center">

                <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                     :key="`menu_product_${product.id}`">
                    <ProductDrink dark_card :theme_code="theme_code" :product="product" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object,Array,]}
        },
        components: {
            ProductDrink,
        }
    }
</script>