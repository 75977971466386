<template>
    <div class="mandp-section-five">
        <div class="container mandp-section-five--container">
            <div class="mandp-section-five-box">
                <div class="mandp-section-five--header">
                    <h1>{{ section.name }}</h1>
                </div>
                <div class="mandp-section-five--products">

                    <div class="container">

                        <MenuProductFoodRender
                            :theme_code="theme_code"
                            :section="section"
                        />

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>