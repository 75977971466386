<template>
    <h1 class="name-cc animate__animated animate__delay-1s" data-animation="animate__slideInDown">{{ cc.name }}</h1>
    <div class="cc-header--rrss animate__animated animate__delay-1s" data-animation="animate__slideInDown">
        <div v-if="cc.whatsapp !== null" @click="onGoToRS(`https://wa.me/521${cc.whatsapp}`)" class="cc-header--rrss--item">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/004-whatsapp.png">
        </div>
        <div v-if="cc.facebook !== null" @click="onGoToRS(cc.facebook)" class="cc-header--rrss--item">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/002-facebook.png">
        </div>
        <div v-if="cc.instagram !== null" @click="onGoToRS(cc.instagram)" class="cc-header--rrss--item">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/003-social.png">
        </div>
        <div v-if="cc.twitter !== null" @click="onGoToRS(cc.twitter)" class="cc-header--rrss--item">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/001-twitter.png">
        </div>
        <div v-if="cc.youtube !== null" @click="onGoToRS(cc.youtube)" class="cc-header--rrss--item">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/005-youtube.png">
        </div>
    </div>
    <div v-if="has_promos" class="cc-header--promo-cta">
        <Button label="Promociones" />
    </div>
</template>

<script>
    import Button from 'primevue/button'

    export default {
        props: {
            cc: { required: true, type: Object },
            has_promos: { required: false, type: Boolean, default: true, }
        },
        methods: {
            onGoToRS(url){
                this.$confirm.require({
                    header: 'Aviso de enlace externo',
                    message: `Usted está saliendo de este sitio web`,
                    acceptLabel: 'Confirmar',
                    rejectLabel: 'Cancelar',
                    accept: () => {
                        return window.open(url, '_blank')
                    },
                    reject: () => { return false }
                })
            }
        },
        components: {
            Button,
        }
    }
</script>
