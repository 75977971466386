<template>
    <div class="mandp-two-column">
        <h1>{{ section.name }}</h1>
        <div class="mandp-two-column--container">
            <div class="container">
                <template v-if=" section.grid_type === 'list' ">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center mandp-two-column--img">
                            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes/skus/strongbow-theme.png">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <ProductDrink v-for="product in section.products"
                                          :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                        </div>
                    </div>
                </template>

                <div v-else class="row">
                    <div class="row align-items-center justify-content-center">
                        <div class="order-2 order-sm-2 order-lg-2 col-sm-12 col-md-12 col-lg-6 mandp-two-column--products">
                            <div class="products-container row inner justify-content-center">
                                <div class="product-card gx-4 gy-4 col-sm-12 col-md-6 col-lg-6" v-for="product in FirstFourProducts"
                                     :key="`menu_product_${product.id}`">
                                    <ProductDrink :theme_code="theme_code" :product="product" />
                                </div>
                            </div>
                        </div>
                        <div
                            class="order-sm-1 order-md-1 order-lg-2 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mandp-two-column--img">
                            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes/skus/strongbow-theme.png">
                        </div>
                    </div>
                    <div v-if="NextProducts.length > 0" class="products-container row justify-content-center">
                        <div class="product-card gx-4 gy-4 col-sm-12 col-md-6 col-lg-3" v-for="product in NextProducts"
                             :key="`menu_product_${product.id}`">
                            <ProductDrink :theme_code="theme_code" :product="product" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductDrink from "@/components/General/Product-Drink"

export default {
    props: {
        theme_code: {required: true, type: String,},
        section: {required: true, type: [Object, Array,]}
    },
    computed: {
        FirstFourProducts(){
            return this.section.products?.slice(0, 4)
        },
        NextProducts(){
            let products_length = this.section.products.length
            return this.section.products?.slice(4, products_length)
        }
    },
    components: {
        ProductDrink,
    }
}
</script>