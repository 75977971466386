<template>
    <div class="mandp-promo--card" :class="`${theme_code} ${promo.classes}`">
        <div class="mandp-promo--col promo-info-col">

            <div class="mandp-promo--card--cc">
                <div class="mandp-promo--card--logo-cc">
                    <img :src="cc.logo_img" alt="">
                </div>
                <p class="mandp-promo--card--name-cc">{{ cc.name }}</p>
            </div>

            <div class="mandp-promo--card--cont">
                <div class="mandp-promo--card--cta">
                    <p>{{ promo.cta_text }}</p>
                </div>
                <div class="mandp-promo--card--description">
                    <p>{{ promo.description }}</p>
                </div>
            </div>

        </div>

        <div class="mandp-promo--col promo-img-col">
            <img :src="promo.img_url" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        theme_code: { required: true, type: String,  },
        promo:{ required: true, type: Object },
        cc: { required: true, type: Object },
    },
    data(){
        return {

        }
    }
}
</script>