<template>
    <div class="container line-one">
        <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FCER-PREM-2%2Fassets%2Fcer-prem-2-line.png" alt="">
    </div>
    <div class="mandp-section-one">
        <h1>{{ section.name }}</h1>
    </div>
    <div class="container">
        <template v-if=" section.grid_type === 'list' ">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center mandp-two-column--img">
                    <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FCER-PREM-2%2Fassets%2Fimage-section-one.png">
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <ProductFood v-for="product in section.products" dark_card
                                  :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                </div>
            </div>
        </template>

        <div v-else class="mandp-section-one--container">
            <div class="row align-items-center justify-content-center">
                <div class="order-2 order-lg-1 order-sm-2 order-sm-2 col-sm-12 col-md-12 col-lg-8 mandp-section-one--products">
                    <div class="products-container row inner justify-content-center">
                        <div :class="`product-card gx-4 gy-4 ${CardSizeClasses_COL}`" v-for="product in FirstFourProducts"
                             :key="`menu_product_${product.id}`">
                            <ProductFood dark_card :theme_code="theme_code" :product="product" />
                        </div>
                    </div>
                </div>
                <div class="order-1 order-lg-2 order-md-1 order-sm-1 col-sm-12 col-md-12 col-lg-4 mandp-section-one--image">
                    <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FCER-PREM-2%2Fassets%2Fimage-section-one.png">
                </div>
            </div>
            <div v-if="NextProducts.length > 0" class="products-container order-3 row justify-content-center">
                <div :class="`product-card gx-4 gy-4 ${CardSizeClasses_ROW}`" v-for="product in NextProducts"
                     :key="`menu_product_${product.id}`">
                    <ProductFood dark_card :theme_code="theme_code" :product="product" />
                </div>
            </div>
        </div>
    </div>
    <div class="mandp-separator"></div>
</template>

<script>
    import ProductFood from "@/components/General/Product-Food"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        computed: {
            FirstFourProducts(){
                if( this.section.grid_type === 'small-card' ) return this.section.products?.slice(0, 6)
                else return this.section.products?.slice(0, 4)
            },
            NextProducts(){
                let products_length = this.section.products.length
                if( this.section.grid_type === 'small-card' ) return this.section.products?.slice(6, products_length)
                else return this.section.products?.slice(4, products_length)
            },
            CardSizeClasses_COL(){
                if( this.section.grid_type === 'small-card' ) return "col-sm-12 col-md-6 col-lg-4"
                else return "col-sm-12 col-md-6 col-lg-6"
            },
            CardSizeClasses_ROW(){
                if( this.section.grid_type === 'small-card' ) return "col-sm-12 col-md-6 col-lg-3"
                else return "col-sm-12 col-md-6 col-lg-4"
            }
        },
        components: {
            ProductFood,
        }
    }
</script>