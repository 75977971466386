<template>
    <div class="container mandp-section-five">
        <div class="mandp-section-five-header">
            <h1>{{ section.name }}</h1>
        </div>
        <div class="mandp-section-five-products">
            <template v-if=" section.grid_type === 'list' ">
                <ProductDrink v-for="product in section.products"
                              :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
            </template>
            <div v-else class="row">
                <div class="gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                     :key="`menu_product_${product.id}`">
                    <ProductDrink dark_card :theme_code="theme_code" :product="product" />
                </div>
            </div>
        </div>
        <div style="height: 3px; background: #F6CD8B; width: 100%;"></div>
    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object,Array,]}
        },
        components: {
            ProductDrink,
        }
    }
</script>