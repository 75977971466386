<template>
    <div class="mandp-beers-section">
        <div class="grid">
            <div class="col-4"></div>
            <div class="col-8">
                <h1 class="mandp-section-name red animate__animated" data-animation="animate__slideInDown">CERVEZAS</h1>

                <table class="mandp-beers-table">
                    <tr>
                        <th style="width: 60px"></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-if=" Sol " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/sol-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">SOL</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ Sol?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" XXLager " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/xx-Lager-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">XX LAGER</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ XXLager?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" XXAmbar " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/xx-Ambar-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">XX AMBAR</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ XXAmbar?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" Miller " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Miller-Lite-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Miller Lite</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ Miller?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" CoorsLight " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Coors-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Coors Light</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ CoorsLight?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" Indio " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Indio-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Indio</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ Indio?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" TecateO " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Tecate-O-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Tecate original</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ TecateO?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" TecateLight " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Tecate-L-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Tecate light</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ TecateLight?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" CartaBlanca " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Carta-Blanca-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Carta blanca</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ CartaBlanca?.price }}</p>
                        </td>
                    </tr>
                    <tr v-if=" Superior " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                        <td class="beer-list-item--logo">
                            <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Superior-logo.png" alt="">
                        </td>
                        <td colspan="2">
                            <p class="beer-name">Superior</p>
                        </td>
                        <td>
                            <p class="beer-price">${{ Superior?.price }}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array, }
        },
        computed: {
            Sol(){
                return this.products.filter(p => p.name === 'Sol')[0]
            },
            XXLager(){
                return this.products.filter(p => p.name === 'XX Lager')[0]
            },
            XXAmbar(){
                return this.products.filter(p => p.name === 'XX Ambar')[0]
            },
            Miller(){
                return this.products.filter(p => p.name === 'Miller Lite')[0]
            },
            CoorsLight(){
                return this.products.filter(p => p.name === 'Coors Light')[0]
            },
            Indio(){
                return this.products.filter(p => p.name === 'Indio')[0]
            },
            TecateO(){
                return this.products.filter(p => p.name === 'Tecate')[0]
            },
            TecateLight(){
                return this.products.filter(p => p.name === 'Tecate Light')[0]
            },
            CartaBlanca(){
                return this.products.filter(p => p.name === 'Carta Blanca')[0]
            },
            Superior(){
                return this.products.filter(p => p.name === 'Superior')[0]
            },
        }
    }
</script>
