<template>
    <div class="mandp-parr-1--grl">
        <div class="container">
            <div class="parr-1-title-box">
                <h1>{{ section.name }}</h1>
            </div>
            <div class="mandp--parr--products">

                <template v-if=" section.grid_type === 'list' ">
                    <ProductDrink v-for="product in section.products"
                                 :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                </template>

                <template v-else>
                    <div class="row products-container justify-content-center">
                        <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                             :key="`menu_product_${product.id}`">
                            <ProductDrink :theme_code="theme_code" :product="product" />
                        </div>
                    </div>
                </template>

            </div>
        </div>
        <div class="parr-1-beers-bottom">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes/skus/cheves.png" alt="">
        </div>
    </div>
</template>

<script>
import ProductDrink from "@/components/General/Product-Drink"

export default {
    props: {
        theme_code: {required: true, type: String,},
        section: {required: true, type: [Object, Array,]}
    },
    components: {
        ProductDrink,
    }
}
</script>