<template>
    <div class="mandp-platf nb">
        <div class="mandp-platf--header no-image"></div>
        <div class="container">
            <h1>{{ section.name }}</h1>
            <template v-if=" section.grid_type === 'list' ">
                <ProductDrink v-for="product in section.products"
                              :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
            </template>

            <div v-else class="products-container row justify-content-center">
                <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                     :key="`menu_product_${product.id}`">
                    <ProductDrink :theme_code="theme_code" :product="product" />
                </div>
            </div>
            <div class="mandp-platf-marcas">
                <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2Fskus%2Fcervezas-logos.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            ProductDrink,
        }
    }
</script>