<template>
    <div class="mandp-section-one">
        <h1>({{ section.name }})</h1>
    </div>
    <div class="container">

        <MenuProductFoodRender
            :theme_code="theme_code"
            :section="section"
            dark_card
        />

    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>