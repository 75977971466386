<template>
    <div class="mandp-product-card drink" :class="{ 'black': dark_card }" @click="onWatchProductDetails">
        <div v-if="product.product_img !== ''" class="mandp-product-card--image">
            <img :src="product.product_img" alt="">
        </div>
        <div class="mandp-product-card--body">
            <p class="mandp-product-card--name">
                {{ product.name }}
            </p>
            <p class="mandp-product-card--description">{{ product.description }}</p>
            <p class="mandp-product-card--price">
                $ {{ product.price }}
                <span v-if="product.weight" class="mandp-product-card--weight">
                    {{ product.weight }} {{ product.weight_type || '' }}
                </span>
            </p>
            <template v-if="product.has_price_variables">
                <div v-for="(price, i) in product.prices" :key="`price_${product.id}_${i}`" class="mandp-product-card--price--container">
                    <p class="mandp-product-card--price-name">
                        {{ price.name }}
                    </p>
                    <p class="mandp-product-card--price">
                        $ {{ price.price }}
                        <span v-if="price.weight" class="mandp-product-card--weight">
                            {{ price.weight || '' }} {{ price.weight_type || '' }}
                        </span>
                    </p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: { required: true, type: [ Object, Array ] },
        theme_code: { required: true, type: String },
        dark_card: { required: false, type: Boolean, default: false },
    },
    methods: {
        onWatchProductDetails(){
            this.$emitter.emit('mandp--product-details', {product: this.product, is_drink: true})
        }
    }
}
</script>