<template>
    <div :class="`mandp-menu-section ${theme_code}`">
        <h1 :class="`mandp-menu-section--name ${theme_code}`">{{ section.name }}</h1>

        <div class="container">

            <div class="grid flex justify-content-center">

                <MenuProduct v-for="product in section.products" :key="`menu_product_${product.id}`" :product="product" :theme_code="theme_code" />

            </div>

        </div>
    </div>
</template>

<script>
    import MenuProduct from "@/components/Menu/MenuProduct"

    export default {
        props: {
            theme_code: { required: true, type: String, },
            section: { required: true, type: [ Object, Array, ] }
        },
        components: {
            MenuProduct,
        }
    }
</script>