<template>
    <div style="width: 100vw; height: 100vh; display: flex; align-items: center; justify-content: center">
        <h1>Menu not found</h1>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.$emitter.emit('mandp--global-loading', false)
        }
    }
</script>