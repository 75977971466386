<template>
    <div class="mandp--entr">
        <div class="container">
            <h1>{{ section.name }}</h1>
            <div class="mandp--platf--riz">
                <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMED-1%2Fassets%2Fmed-1-rizg.png"
                     alt="">
            </div>
            <div class="mandp--entr--products">

                <MenuProductFoodRender
                    :theme_code="theme_code"
                    :section="section"
                    primary_theme_color
                />

            </div>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>