<template>
    <div ref="container" :class="`mandp-menu-beers-non-alcoholic`">
        <div ref="title-container" class="container">
            <h1 class="mandp-section-name animate__animated" data-animation="animate__slideInDown">Sin alcohol</h1>
        </div>
        <div ref="grid" class="grid justify-content-center">

            <div v-if=" Heineken " ref="hnk-col" class="col col-sm-6 col-md-6 col-6 hnk animate__animated" data-animation="animate__slideInLeft">
                <img ref="heineken-bottle" class="mandp-non-alcoholic--hnk-b" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/hnk-00-bottle.png" alt="">
                <div ref="heineken-price" class="mandp-non-alcoholic-hnk--price">
                    <p>${{ Heineken?.price }}</p>
                </div>
                <div ref="heineken-info" class="mandp-non-alcoholic-hnk--info">
                    <p>HEINEKEN</p>
                    <p>0.0</p>
                    <p><small>250ml </small></p>
                </div>
            </div>

            <div v-if=" Tecate " ref="tkt-col" class="col col-sm-6 col-md-6 col-6 tkt animate__animated" data-animation="animate__slideInRight">
                <img ref="tecate-bottle" class="mandp-non-alcoholic--tkt-b" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/tkt-00-bottle.png" alt="">
                <div ref="tecate-price" class="mandp-non-alcoholic-tkt--price">
                    <p>${{ Tecate?.price }}</p>
                </div>
                <div ref="tecate-info" class="mandp-non-alcoholic-tkt--info">
                    <p>Tecate</p>
                    <p>0.0</p>
                    <p><small>355ml</small></p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array }
        },
        computed: {
            Heineken(){
                return this.products.filter(p => p.name === 'Heineken 0.0')[0]
            },
            Tecate(){
                return this.products.filter(p => p.name === 'Tecate 0.0')[0]
            }
        },
        methods: {
            setGridHeight(){
                let container = this.$refs.container.offsetHeight
                let title_container = this.$refs["title-container"].offsetHeight
                let grid_size = container - title_container
                window.$( this.$refs.grid ).css({ height: `${grid_size}px` })
            },
            positionHeinekenPrice(){
                let bottle_size = this.$refs["heineken-bottle"].offsetWidth
                //let bottle_height = this.$refs["heineken-bottle"].offsetHeight
                let bottle_space = window.$(this.$refs["heineken-bottle"]).offset()
                let price_position = bottle_size - bottle_space.left - 50

                window.$( this.$refs["heineken-price"] ).css({ 'marginLeft': `${price_position}px` })
                window.$( this.$refs["heineken-info"] ).css({
                    'marginLeft': `${price_position + 50}px`,
                    //'marginBottom': `${bottle_height/3}px`
                })
            },
            positionTecatePrice(){
                let bottle_size = this.$refs["tecate-bottle"].offsetWidth
                let price_position = bottle_size - 50
                window.$( this.$refs["tecate-price"] ).css({ 'marginRight': `${price_position}px` })
                window.$( this.$refs["tecate-info"] ).css({
                    'marginRight': `${price_position+40}px`,
                })
            }
        },
        mounted() {
            let self = this

            // Set items
            //this.setGridHeight()
            if( this.Heineken ) this.positionHeinekenPrice()
            if( this.Tecate ) this.positionTecatePrice()

            window.$(window).on('resize', function(){
                //self.setGridHeight()
                if( self.Heineken ) self.positionHeinekenPrice()
                if( self.Tecate ) self.positionTecatePrice()
            });

            // Animations
            const intersectionCallback = (entries) => {
                for (const entry of entries) { // Loop over all elements that either enter or exit the view.
                    if (entry.isIntersecting) { // This is true when the element is in view.
                        entry.target.classList.add( window.$(entry.target).data('animation') ); // Add a class.
                    }
                }
            }

            /**
             * Create a observer and use the instersectionCallback as
             * the instructions for what to do when an element enters
             * or leaves the view
             */
            const observer = new IntersectionObserver(intersectionCallback);

            /**
             * Get all .item elements and loop over them.
             * Observe each individual item.
             */
            const items = document.querySelectorAll('.animate__animated');
            for (const item of items) {
                observer.observe(item);
            }

            //console.log({ container, title_container })
        }
    }
</script>
