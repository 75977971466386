<template>
    <div class="mex-2-entr--container">
        <div class="mex-2-entr--box">
            <div class="container">
                <div class="mex-2-title-container">
                    <div class="mex-2-title-line"></div>
                    <h1>{{ section.name }}</h1>
                </div>
                <div class="mandp--entr--products">
                    <template v-if=" section.grid_type === 'list' ">
                        <ProductDrink v-for="product in section.products"
                                      :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                    </template>

                    <div v-else class="row products-container justify-content-center">
                        <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                             :key="`menu_product_${product.id}`">
                            <ProductDrink primary_theme_color :theme_code="theme_code" :product="product" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductDrink from "@/components/General/Product-Drink"

export default {
    props: {
        theme_code: {required: true, type: String,},
        section: {required: true, type: [Object, Array,]}
    },
    components: {
        ProductDrink,
    }
}
</script>