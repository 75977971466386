<template>
    <div class="mandp-platf nb">
        <div class="mandp-platf--header no-image"></div>
        <div class="container">
            <div class="mandp-platf--mar-img">
                <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMAR-PREM-2%2Fassets%2Fmar-per-2-mar.png"
                     alt="">
            </div>
            <h1>{{ section.name }}</h1>

            <MenuProductFoodRender
                :theme_code="theme_code"
                :section="section"
            />

        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>