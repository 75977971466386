<template>

    <template v-if=" $parent.section.grid_type === 'full-card' ">
        <FullCard :theme_code="theme_code" :product="product" :dark_card="dark_card" :primary_theme_color="primary_theme_color" />
    </template>

    <template v-else-if=" $parent.section.grid_type === 'small-card' ">
        <CompactCard :theme_code="theme_code" :product="product" :dark_card="dark_card" :primary_theme_color="primary_theme_color" />
    </template>

    <template v-else>
        <ListCard :product="product" />
    </template>

</template>

<script>
    import ListCard from "@/components/General/ProductsGridTypes/Food/ListCard"
    import FullCard from "@/components/General/ProductsGridTypes/Food/FullCard"
    import CompactCard from "@/components/General/ProductsGridTypes/Food/CompactCard"

    export default {
        props: {
            product: { required: true, type: [ Object, Array ] },
            theme_code: { required: true, type: String },
            dark_card: { required: false, type: Boolean, default: false },
            primary_theme_color: { required: false, type: Boolean, default: false },
        },
        components: {
            ListCard,
            FullCard,
            CompactCard,
        }
    }
</script>