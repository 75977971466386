<template>
    <div class="mex-2-entr--container">
        <div class="mex-2-entr--box">
            <div class="container">
                <div class="mex-2-title-container">
                    <div class="mex-2-title-line"></div>
                    <h1>{{ section.name }}</h1>
                </div>
                <div class="mandp--entr--products">

                    <MenuProductFoodRender
                        :theme_code="theme_code"
                        :section="section"
                    />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>