<template>
    <div ref="container" class="mandp-heineken-section">
        <div class="grid">
            <div class="col col-sm-5 col-md-5 col-5">
                <p>&nbsp;</p>
            </div>
            <div ref="content" class="col col-sm-7 col-md-7 col-7 content-col">

                <div class="grid">

                    <div v-if=" Heineken " class="col-6 hnk-section-card animate__animated" data-animation="animate__slideInLeft">
                        <div class="mandp-beers-card">
                            <div class="mandp-beers-card--container">
                                <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/mandp-hnk-bottle.png" alt="">
                                <div class="mandp-beers-card--info">
                                    <p>Heineken</p>
                                    <p><small>355ml</small></p>
                                </div>
                                <div class="mandp-beers-card--price">
                                    <p>${{ Heineken?.price }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if=" HeinekenSilver " class="col-6 hnk-section-card animate__animated" data-animation="animate__slideInRight">
                        <div class="mandp-beers-card">
                            <div class="mandp-beers-card--container">
                                <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/mando-hnk-silver-bottle.png" alt="">
                                <div class="mandp-beers-card--info">
                                    <p>Heineken Silver</p>
                                    <p><small>355ml</small></p>
                                </div>
                                <div class="mandp-beers-card--price">
                                    <p>${{ HeinekenSilver?.price }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if=" Amstel " class="col-6 hnk-section-card animate__animated" data-animation="animate__slideInLeft">
                        <div class="col">
                            <div class="mandp-beers-card">
                                <div class="mandp-beers-card--container">
                                    <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/mando-amstel-bottle.png" alt="">
                                    <div class="mandp-beers-card--info">
                                        <p>Amstel Ultra</p>
                                        <p><small>355ml</small></p>
                                    </div>
                                    <div class="mandp-beers-card--price">
                                        <p>${{ Amstel?.price }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if=" Miller " class="col-6 hnk-section-card animate__animated" data-animation="animate__slideInRight">
                        <div class="col">
                            <div class="mandp-beers-card">
                                <div class="mandp-beers-card--container">
                                    <img class="mandp-beers-card--bottle" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/mandp-miller-bottle.png" alt="">
                                    <div class="mandp-beers-card--info">
                                        <p>Miller High Life</p>
                                        <p><small>355ml</small></p>
                                    </div>
                                    <div class="mandp-beers-card--price">
                                        <p>${{ Miller?.price }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            products: { required: true, type: Array, }
        },
        computed: {
            Heineken(){
                return this.products.filter(p => p.name === 'Heineken')[0]
            },
            HeinekenSilver(){
                return this.products.filter(p => p.name === 'Heineken Silver')[0]
            },
            Amstel(){
                return this.products.filter(p => p.name === 'Amstel Ultra')[0]
            },
            Miller(){
                return this.products.filter(p => p.name === 'Miller High Life')[0]
            }
        },
        mounted(){

            let container_height = this.$refs.container.offsetHeight
            window.$( this.$refs.content ).css({ 'height': `${container_height}px` })

        }
    }
</script>
