<template>
    <p v-if="loading">Loading menu</p>

    <template v-else>
        <div class="mandp--pdf-menu">
            <ConfirmDialog />
            <MenuHeader :menu="menu" :theme_code="ThemeCode" :cc="menu.cc" :has_promos="MenuHasPromos" />
            <div class="container">
                <img class="mandp--pdf-menu--img" v-for="(img, i) in menu.images" :key="`img_${i}`" :src="img.img_url" alt="">
            </div>
            <MenuFooter :theme_code="ThemeCode" />
        </div>
    </template>
</template>

<script>
    import MenuHeader from "@/components/Menu/MenuHeader"
    import MenuFooter from "@/components/General/MenuFooter"
    import ConfirmDialog from 'primevue/confirmdialog'

    export default {
        data(){
            return {
                menu: null,
                hnk_promos: [],
                loading: true,
                show_promos_popup: true,
            }
        },
        computed: {
            ThemeCode(){
                return ''
            },
            MenuHasPromos(){
                let promos_count = 0
                promos_count += this.menu?.promotions?.length || 0
                promos_count += this.hnk_promos?.length || 0
                return promos_count
                //return this.menu?.promotions?.length > 0 || false
            },
        },
        methods: {
            fetchMenuData(){
                window.axios.post(`${this.$global.API_URL}/get-menu-pdf/${this.$route.params.uid}`, {
                    'analytic_source': this.$route.query.utm_source || 'web',
                })
                    .then(response => {
                        if( response.data.success ){
                            this.menu = response.data.menu
                            this.hnk_promos = response.data.hnk_promotions
                            this.loading = false

                            // Set page title to CC name
                            document.title = this.menu.cc.name

                            let self = this
                            setTimeout(() => {
                                self.$emitter.emit('mandp--global-loading', false)
                                if( self.MenuHasPromos ) self.$emitter.emit('menu--promos-sidebar')
                            }, 1000)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        if( error.response.status === 404 ) this.$router.replace('/404')
                    })
            }
        },
        mounted() {
            this.fetchMenuData()

            if( this.$route.query.promo_popup ) this.show_promos_popup = (this.$route.query.promo_popup === 'true' )
        },
        components: {
            MenuHeader,
            MenuFooter,
            ConfirmDialog,
        }
    }
</script>