<template>
    <div class="mandp-product-card small" :class="{ 'black': dark_card, 'primary-theme': primary_theme_color }" @click="onWatchProductDetails">
        <div v-if="product.product_img !== ''" class="mandp-product-card--image">
            <img :src="product.product_img" alt="">
        </div>
        <div class="mandp-product-card--body">
            <div class="grid">
                <div style="padding-bottom: 0" class="col">
                    <p class="mandp-product-card--name">
                        {{ product.name }}
                        <span v-if="product.weight !== '' && product.weight !== null && ! product.has_price_variables"
                              class="mandp-product-card--weight">
                            {{ product.weight }} {{ product.weight_type }}
                        </span>
                    </p>
                </div>
                <div style="padding-bottom: 0" class="col-fixed">
                    <p class="mandp-product-card--price">
                        $ {{ product.price }}
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: { required: true, type: [ Object, Array ] },
        theme_code: { required: true, type: String },
        dark_card: { required: false, type: Boolean, default: false },
        primary_theme_color: { required: false, type: Boolean, default: false },
    },
    methods: {
        onWatchProductDetails(){
            this.$emitter.emit('mandp--product-details', { product: this.product })
        }
    }
}
</script>