<template>
    <div class="mandp-parr-1--platf">
        <div class="parr-1-platf-box">
            <h1>{{ section.name }}</h1>
        </div>
        <div class="container">
            <div class="mandp--parr--products">

                <MenuProductFoodRender
                    :theme_code="theme_code"
                    :section="section"
                />

            </div>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>