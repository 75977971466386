<template>
    <div class="mandp-pizz-1--grl">
        <div class="container">
            <div class="pizz-1-title-box">
                <h1>{{ section.name }}</h1>
            </div>
            <div class="mandp--parr--products">

                <MenuProductFoodRender
                    :theme_code="theme_code"
                    :section="section"
                    dark_card
                />

            </div>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>