<template>
    <div :class="`mandp-menu-body ${theme_code}`">

        <!--<MenuSection v-for="section in sections" :key="`menu_section_${section.id}`" :section="section" :theme_code="theme_code" /> -->

        <div v-for="(section, index) in OrderedSections" :key="`menu_section_${index}`" :id="`section_${index}`"
             :class="`mandp-section ${section.theme_section_code} gird-type-${section.grid_type}`">

            <component :is="checkIfThemeComponentExists(section.theme_section_code) ? section.theme_section_code :
            getDefaultComponent(section)" :section="section" :theme_code="theme_code" />

        </div>

    </div>
</template>

<script>
    import { resolveDynamicComponent } from "vue"
    import MenuSection from "@/components/Menu/MenuSection"

    // Theme: CER-EST-1
    import CER_EST_1_ENTR from '@/components/Themes/CER-EST-1/sections/CER-EST-1-ENTR'
    import CER_EST_1_CERV from '@/components/Themes/CER-EST-1/sections/CER-EST-1-CERV'
    import CER_EST_1_CAGU from '@/components/Themes/CER-EST-1/sections/CER-EST-1-CAGU'
    import CER_EST_1_ARTE from '@/components/Themes/CER-EST-1/sections/CER-EST-1-ARTE'
    import CER_EST_1_CIDER from '@/components/Themes/CER-EST-1/sections/CER-EST-1-CIDER'
    import CER_EST_1_HASEL from '@/components/Themes/CER-EST-1/sections/CER-EST-1-HASEL'
    import CER_EST_1_PLATF from '@/components/Themes/CER-EST-1/sections/CER-EST-1-PLATF'
    import CER_EST_1_GRL_FOOD from '@/components/Themes/CER-EST-1/sections/CER-EST-1-GRL-FOOD'
    import CER_EST_1_GRL_DRINKS from '@/components/Themes/CER-EST-1/sections/CER-EST-1-GRL-DRINKS'

    // Theme: CER-EST-2
    import CER_EST_2_ENTR from '@/components/Themes/CER-EST-2/sections/CER-EST-2-ENTR'
    import CER_EST_2_CERV from '@/components/Themes/CER-EST-2/sections/CER-EST-2-CERV'
    import CER_EST_2_PLATF from '@/components/Themes/CER-EST-2/sections/CER-EST-2-PLATF'
    import CER_EST_2_CAGU from '@/components/Themes/CER-EST-2/sections/CER-EST-2-CAGU'
    import CER_EST_2_CIDER from '@/components/Themes/CER-EST-2/sections/CER-EST-2-CIDER'
    import CER_EST_2_HASEL from '@/components/Themes/CER-EST-2/sections/CER-EST-2-HASEL'
    import CER_EST_2_ARTE from '@/components/Themes/CER-EST-2/sections/CER-EST-2-ARTE'
    import CER_EST_2_GRL_FOOD from '@/components/Themes/CER-EST-2/sections/CER-EST-2-GRL-FOOD'
    import CER_EST_2_GRL_DRINKS from '@/components/Themes/CER-EST-2/sections/CER-EST-2-GRL-DRINKS'

    // Theme: CER-EST-3
    import CER_EST_3_ENTR from '@/components/Themes/CER-EST-3/sections/CER-EST-3-ENTR'
    import CER_EST_3_HAMB from '@/components/Themes/CER-EST-3/sections/CER-EST-3-HAMB'
    import CER_EST_3_PLATF from '@/components/Themes/CER-EST-3/sections/CER-EST-3-PLATF'

    // Theme: CER-PREM-1
    import CER_PREM_1_MAR from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-MAR'
    import CER_PREM_1_CERV from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-CERV'
    import CER_PREM_1_ENTR from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-ENTR'
    import CER_PREM_1_PLATF from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-PLATF'
    import CER_PREM_1_ARTE from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-ARTE'
    import CER_PREM_1_HASEL from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-HASEL'
    import CER_PREM_1_CIDER from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-CIDER'
    import CER_PREM_1_GRL_FOOD from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-GRL-FOOD'
    import CER_PREM_1_GRL_DRINKS from '@/components/Themes/CER-PREM-1/sections/CER-PREM-1-GRL-DRINKS'

    // Theme: CER-PREM-2
    import CER_PREM_2_CERV from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-CERV'
    import CER_PREM_2_ENTR from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-ENTR'
    import CER_PREM_2_CIDER from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-CIDER'
    import CER_PREM_2_HASEL from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-HASEL'
    import CER_PREM_2_PLATF from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-PLATF'
    import CER_PREM_2_GRL_FOOD from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-GRL-FOOD'
    import CER_PREM_2_GRL_DRINKS from '@/components/Themes/CER-PREM-2/sections/CER-PREM-2-GRL-DRINKS'

    // Theme: MAR-EST-1
    import MAR_EST_1_CIDER from '@/components/Themes/MAR-EST-1/sections/MAR-EST-1-CIDER'
    import MAR_EST_1_HASEL from '@/components/Themes/MAR-EST-1/sections/MAR-EST-1-HASEL'
    import MAR_EST_1_GRL_FOOD from '@/components/Themes/MAR-EST-1/sections/MAR-EST-1-GRL-FOOD'
    import MAR_EST_1_GRL_DRINKS from '@/components/Themes/MAR-EST-1/sections/MAR-EST-1-GRL-DRINKS'

    // Theme: MAR-EST-2
    import MAR_EST_2_MAR from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-MAR'
    import MAR_EST_2_CAGU from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-CAGU'
    import MAR_EST_2_ARTE from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-ARTE'
    import MAR_EST_2_CIDER from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-CIDER'
    import MAR_EST_2_HASEL from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-HASEL'
    import MAR_EST_2_PLATF from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-PLATF'
    import MAR_EST_2_GRL_FOOD from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-GRL-FOOD'
    import MAR_EST_2_GRL_DRINKS from '@/components/Themes/MAR-EST-2/sections/MAR-EST-2-GRL-DRINKS'

    // Theme: MAR-PREM-1
    import MAR_PREM_1_ENTR from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-ENTR'
    import MAR_PREM_1_MAR from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-MAR'
    import MAR_PREM_1_CERV from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-CERV'
    import MAR_PREM_1_ARTE from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-ARTE'
    import MAR_PREM_1_CIDER from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-CIDER'
    import MAR_PREM_1_HASEL from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-HASEL'
    import MAR_PREM_1_GRL_FOOD from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-GRL-FOOD'
    import MAR_PREM_1_GRL_DRINKS from '@/components/Themes/MAR-PREM-1/sections/MAR-PREM-1-GRL-DRINKS'

    // Theme: MAR-PREM-2
    import MAR_PREM_2_MAR from '@/components/Themes/MAR-PREM-2/sections/MAR-PREM-2-MAR'
    import MAR_PREM_2_ENTR from '@/components/Themes/MAR-PREM-2/sections/MAR-PREM-2-ENTR'
    import MAR_PREM_2_PLATF from '@/components/Themes/MAR-PREM-2/sections/MAR-PREM-2-PLATF'
    import MAR_PREM_2_CERV from '@/components/Themes/MAR-PREM-2/sections/MAR-PREM-2-CERV'
    import MAR_PREM_2_GRL_FOOD from '@/components/Themes/MAR-PREM-2/sections/MAR-PREM-2-GRL-FOOD'
    import MAR_PREM_2_GRL_DRINKS from '@/components/Themes/MAR-PREM-2/sections/MAR-PREM-2-GRL-DRINKS'

    // Theme: MED-1
    import MED_1_CERV from '@/components/Themes/MED-1/sections/MED-1-CERV'
    import MED_1_CIDER from '@/components/Themes/MED-1/sections/MED-1-CIDER'
    import MED_1_HASEL from '@/components/Themes/MED-1/sections/MED-1-HASEL'
    import MED_1_ENTR from '@/components/Themes/MED-1/sections/MED-1-ENTR'
    import MED_1_PLATF from '@/components/Themes/MED-1/sections/MED-1-PLATF'
    import MED_1_GRL_FOOD from '@/components/Themes/MED-1/sections/MED-1-GRL-FOOD'
    import MED_1_GRL_FOOD_NBG from '@/components/Themes/MED-1/sections/MED-1-GRL-FOOD-NBG'
    import MED_1_GRL_DRINKS from '@/components/Themes/MED-1/sections/MED-1-GRL-DRINKS'

    // Theme: MEX-1
    import MEX_1_ENTR from '@/components/Themes/MEX-1/sections/MEX-1-ENTR'
    import MEX_1_PLATF from '@/components/Themes/MEX-1/sections/MEX-1-PLATF'
    import MEX_1_CERV from '@/components/Themes/MEX-1/sections/MEX-1-CERV'
    import MEX_1_CIDER from '@/components/Themes/MEX-1/sections/MEX-1-CIDER'
    import MEX_1_HASEL from '@/components/Themes/MEX-1/sections/MEX-1-HASEL'
    import MEX_1_GRL_DRINKS from '@/components/Themes/MEX-1/sections/MEX-1-GRL-DRINKS'
    import MEX_1_GRL_FOOD from '@/components/Themes/MEX-1/sections/MEX-1-GRL-FOOD'

    // Theme: MEX-2
    import MEX_2_GRL_FOOD from '@/components/Themes/MEX-2/sections/MEX-2-GRL-FOOD'
    import MEX_2_GRL_DRINKS from '@/components/Themes/MEX-2/sections/MEX-2-GRL-DRINKS'
    import MEX_2_CIDER from '@/components/Themes/MEX-2/sections/MEX-2-CIDER'
    import MEX_2_HASEL from '@/components/Themes/MEX-2/sections/MEX-2-HASEL'

    // Theme: PARR-1
    import PARR_1_GRL_FOOD from '@/components/Themes/PARR-1/sections/PARR-1-GRL-FOOD'
    import PARR_1_GRL_DRINKS from '@/components/Themes/PARR-1/sections/PARR-1-GRL-DRINKS'
    import PARR_1_PLATF from '@/components/Themes/PARR-1/sections/PARR-1-PLATF'
    import PARR_1_CERV from '@/components/Themes/PARR-1/sections/PARR-1-CERV'
    import PARR_1_CIDER from '@/components/Themes/PARR-1/sections/PARR-1-CIDER'
    import PARR_1_HASEL from '@/components/Themes/PARR-1/sections/PARR-1-HASEL'

    // Theme: PIZZ-1
    import PIZZ_1_ENTR from '@/components/Themes/PIZZ-1/sections/PIZZ-1-ENTR'
    import PIZZ_1_CERV from '@/components/Themes/PIZZ-1/sections/PIZZ-1-CERV'
    import PIZZ_1_PLATF from '@/components/Themes/PIZZ-1/sections/PIZZ-1-PLATF'
    import PIZZ_1_GRL_FOOD from '@/components/Themes/PIZZ-1/sections/PIZZ-1-GRL-FOOD'
    import PIZZ_1_GRL_DRINKS from '@/components/Themes/PIZZ-1/sections/PIZZ-1-GRL-DRINKS'
    import PIZZ_1_HASEL from '@/components/Themes/PIZZ-1/sections/PIZZ-1-HASEL'
    import PIZZ_1_CIDER from '@/components/Themes/PIZZ-1/sections/PIZZ-1-CIDER'

    // Theme: PIZZ-2
    import PIZZ_2_ENTR from '@/components/Themes/PIZZ-2/sections/PIZZ-2-ENTR'
    import PIZZ_2_PLATF from '@/components/Themes/PIZZ-2/sections/PIZZ-2-PLATF'
    import PIZZ_2_GRL_FOOD from '@/components/Themes/PIZZ-2/sections/PIZZ-2-GRL-FOOD'
    import PIZZ_2_GRL_DRINKS from '@/components/Themes/PIZZ-2/sections/PIZZ-2-GRL-DRINKS'
    import PIZZ_2_CIDER from '@/components/Themes/PIZZ-2/sections/PIZZ-2-CIDER'
    import PIZZ_2_HASEL from '@/components/Themes/PIZZ-2/sections/PIZZ-2-HASEL'

    // Theme: SP BAR 1
    import SP_BAR_1_ENTR from '@/components/Themes/SP-BAR-1/sections/SP-BAR-1-ENTR'
    import SP_BAR_1_PLATF from '@/components/Themes/SP-BAR-1/sections/SP-BAR-1-PLATF'
    import SP_BAR_1_GRL_FOOD from '@/components/Themes/SP-BAR-1/sections/SP-BAR-1-GRL-FOOD'
    import SP_BAR_1_GRL_DRINKS from '@/components/Themes/SP-BAR-1/sections/SP-BAR-1-GRL-DRINKS'
    import SP_BAR_1_HASEL from '@/components/Themes/SP-BAR-1/sections/SP-BAR-1-HASEL'
    import SP_BAR_1_CIDER from '@/components/Themes/SP-BAR-1/sections/SP-BAR-1-CIDER'

    // Theme: SP BAR 2
    import SP_BAR_2_ENTR from '@/components/Themes/SP-BAR-2/sections/SP-BAR-2-ENTR'
    import SP_BAR_2_PLATF from '@/components/Themes/SP-BAR-2/sections/SP-BAR-2-PLATF'
    import SP_BAR_2_CIDER from '@/components/Themes/SP-BAR-2/sections/SP-BAR-2-CIDER'
    import SP_BAR_2_HASEL from '@/components/Themes/SP-BAR-2/sections/SP-BAR-2-HASEL'
    import SP_BAR_2_GRL_FOOD from '@/components/Themes/SP-BAR-2/sections/SP-BAR-2-GRL-FOOD'
    import SP_BAR_2_GRL_DRINKS from '@/components/Themes/SP-BAR-2/sections/SP-BAR-2-GRL-DRINKS'

    export default {
        props: {
            theme_code: { required: true, type: String, },
            sections: { required: true, type: Array, }
        },
        computed:{
            OrderedSections(){
                return window._.orderBy(this.sections, ['order'])
            },
        },
        methods: {
            checkIfThemeComponentExists(component_name){
                return typeof resolveDynamicComponent(component_name) !== 'string'
            },
            getDefaultComponent(section){
                let component = this.theme_code.replaceAll('-', '_')
                if( section.category === 'drinks' ) component += `_GRL_DRINKS`
                else component += `_GRL_FOOD`
                return component
            }
        },
        mounted() {
            window.$(document).ready(() => {
                window.$('.products-container').masonry({
                    itemSelector: '.product-card',
                    horizontalOrder: true,
                })

                setTimeout(() => {
                    window.$('.products-container').masonry('reloadItems')
                }, 1000)
            })

            window.$('.mandp-animate-backInRight').viewportChecker({
                classToAdd: 'animate__backInRight',
                offset: 100,
                repat: false,
            })

        },
        components: {
            MenuSection,
            CER_EST_1_ENTR,
            CER_EST_1_CERV,
            CER_EST_1_CAGU,
            CER_EST_1_ARTE,
            CER_EST_1_CIDER,
            CER_EST_1_HASEL,
            CER_EST_1_PLATF,
            CER_EST_1_GRL_FOOD,
            CER_EST_1_GRL_DRINKS,
            CER_EST_2_ENTR,
            CER_EST_2_CERV,
            CER_EST_2_PLATF,
            CER_EST_2_CIDER,
            CER_EST_2_HASEL,
            CER_EST_2_ARTE,
            CER_EST_2_CAGU,
            CER_EST_3_ENTR,
            CER_EST_3_HAMB,
            CER_EST_3_PLATF,
            CER_EST_2_GRL_FOOD,
            CER_EST_2_GRL_DRINKS,
            CER_PREM_1_MAR,
            CER_PREM_1_ENTR,
            CER_PREM_1_CERV,
            CER_PREM_1_ARTE,
            CER_PREM_1_PLATF,
            CER_PREM_1_HASEL,
            CER_PREM_1_CIDER,
            CER_PREM_1_GRL_FOOD,
            CER_PREM_1_GRL_DRINKS,
            CER_PREM_2_ENTR,
            CER_PREM_2_CIDER,
            CER_PREM_2_CERV,
            CER_PREM_2_HASEL,
            CER_PREM_2_PLATF,
            CER_PREM_2_GRL_FOOD,
            CER_PREM_2_GRL_DRINKS,
            MAR_EST_1_CIDER,
            MAR_EST_1_HASEL,
            MAR_EST_1_GRL_FOOD,
            MAR_EST_1_GRL_DRINKS,
            MAR_EST_2_MAR,
            MAR_EST_2_CAGU,
            MAR_EST_2_ARTE,
            MAR_EST_2_CIDER,
            MAR_EST_2_HASEL,
            MAR_EST_2_PLATF,
            MAR_EST_2_GRL_FOOD,
            MAR_EST_2_GRL_DRINKS,
            MAR_PREM_1_MAR,
            MAR_PREM_1_ENTR,
            MAR_PREM_1_CERV,
            MAR_PREM_1_ARTE,
            MAR_PREM_1_CIDER,
            MAR_PREM_1_HASEL,
            MAR_PREM_1_GRL_FOOD,
            MAR_PREM_1_GRL_DRINKS,
            MAR_PREM_2_MAR,
            MAR_PREM_2_ENTR,
            MAR_PREM_2_CERV,
            MAR_PREM_2_PLATF,
            MAR_PREM_2_GRL_FOOD,
            MAR_PREM_2_GRL_DRINKS,
            MED_1_ENTR,
            MED_1_CERV,
            MED_1_PLATF,
            MED_1_CIDER,
            MED_1_HASEL,
            MED_1_GRL_FOOD,
            MED_1_GRL_DRINKS,
            MED_1_GRL_FOOD_NBG,
            MEX_1_ENTR,
            MEX_1_PLATF,
            MEX_1_CERV,
            MEX_1_GRL_DRINKS,
            MEX_1_CIDER,
            MEX_1_HASEL,
            MEX_1_GRL_FOOD,
            MEX_2_GRL_FOOD,
            MEX_2_GRL_DRINKS,
            MEX_2_CIDER,
            MEX_2_HASEL,
            PARR_1_GRL_FOOD,
            PARR_1_GRL_DRINKS,
            PARR_1_PLATF,
            PARR_1_CERV,
            PARR_1_CIDER,
            PARR_1_HASEL,
            PIZZ_1_ENTR,
            PIZZ_1_CERV,
            PIZZ_1_PLATF,
            PIZZ_1_GRL_FOOD,
            PIZZ_1_GRL_DRINKS,
            PIZZ_1_HASEL,
            PIZZ_1_CIDER,
            PIZZ_2_ENTR,
            PIZZ_2_PLATF,
            PIZZ_2_GRL_FOOD,
            PIZZ_2_GRL_DRINKS,
            PIZZ_2_CIDER,
            PIZZ_2_HASEL,
            SP_BAR_1_ENTR,
            SP_BAR_1_PLATF,
            SP_BAR_1_GRL_FOOD,
            SP_BAR_1_GRL_DRINKS,
            SP_BAR_1_HASEL,
            SP_BAR_1_CIDER,
            SP_BAR_2_ENTR,
            SP_BAR_2_PLATF,
            SP_BAR_2_GRL_FOOD,
            SP_BAR_2_GRL_DRINKS,
            SP_BAR_2_CIDER,
            SP_BAR_2_HASEL,
        }
    }
</script>