<template>
    <div class="mandp-section-one-after">
        <!--<img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMAR-PREM-1%2Fassets%2Fcer-prem-1-grl-top.png"
        alt="">-->
    </div>
    <div class="mandp-section-one-box">
        <div class="container mandp-section-one">
            <div class="mandp-section-one-header">
                <h1 style="padding-top: 30px;">{{ section.name }}</h1>
            </div>
            <div class="mandp-section-one-products">

                <MenuProductFoodRender
                    :theme_code="theme_code"
                    :section="section"
                />

            </div>
        </div>
    </div>
    <div class="mandp-section-one-before">
        <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMAR-PREM-1%2Fassets%2Fsection-one--bottom.png" alt="">
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>