<template>
    <div style="margin-bottom: 250px;" class="mandp-section mandp-section-five--conteiner">
        <div class="mandp-section-five--header">
            <h1>({{ section.name }})</h1>
        </div>

        <div class="container">

            <template v-if=" section.grid_type === 'list' ">
                <ProductDrink v-for="product in section.products"
                              :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
            </template>

            <div v-else class="mandp-section-five--products">

                <div class="products-container row justify-content-center">
                    <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                         :key="`menu_product_${product.id}`">
                        <ProductDrink dark_card :theme_code="theme_code" :product="product" />
                    </div>
                </div>

            </div>
        </div>

        <div class="mandp-section-five-image">
            <center><img src="https://menuandpricing.fra1.digitaloceanspaces.com/themes%2Fskus%2Fcheves.png" alt=""></center>
        </div>
    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        computed: {
            FirstFourProducts(){
                return this.section.products?.slice(0, 4)
            },
            NextProducts(){
                let products_length = this.section.products.length
                return this.section.products?.slice(4, products_length)
            }
        },
        components: {
            ProductDrink,
        }
    }
</script>