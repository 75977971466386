<template>
    <Sidebar v-model:visible="visible" position="right">
        <h2 class="sections-sidebar--cc-name">{{ menu.cc.name }}</h2>
        <div class="sections-sidebar--sections">
            <a v-for="(section, index) in sections" :key="`section_${section.id}`" class="sections-sidebar--sections--item"
               :href="`#section_${index}`" @click="visible=false">
                {{ section.name }}
            </a>
        </div>
    </Sidebar>
</template>

<script>
    import Sidebar from "primevue/sidebar"

    export default {
        props: {
            menu: { required: true, type: [Object, Array] },
            sections: { required: false, type: Array, default: () => [] },
        },
        data(){
            return {
                visible: false,
            }
        },
        mounted() {
            this.$emitter.on('menu--sections-sidebar', () => this.visible = true )
        },
        components: {
            Sidebar,
        }
    }
</script>