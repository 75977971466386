<template>
    <Sidebar v-model:visible="visible" position="bottom" class="mandp-sidebar--promos">
        <h2 class="sections-sidebar--cc-name txt-center">{{ menu.cc.name }}</h2>
        <h3 class="promo-sidebar--title">Promociones</h3>

        <div class="promo-sidebar--promos">
            <div v-for="(promo, index) in hnk_promos" :key="`hnk_promo_${index}`" class="promo-sidebar--promos--item">

                <template v-if="promo.cta_url !== ''">
                    <a class="promo-sidebar--promos--item--hnk-a" :href="promo.cta_url" target="_blank">
                        <img class="promo-sidebar--promos--item--hnk-img" :src="promo.img_url" alt="">
                    </a>
                </template>

                <template v-else>
                    <img class="promo-sidebar--promos--item--hnk-img" :src="promo.img_url" alt="">
                </template>

            </div>

            <div v-for="(promo, index) in menu.promotions" :key="`promo_${index}`" class="promo-sidebar--promos--item">
                <PromoItem :cc="menu.cc" :promo="promo" :theme_code="theme_code" />
            </div>
        </div>
    </Sidebar>
</template>

<script>
    import Sidebar from "primevue/sidebar"
    import PromoItem from "@/components/General/PromoItem"

    export default {
        props: {
            menu: {required: true, type: [Object, Array]},
            hnk_promos: {required: true, type: [Object, Array]},
            theme_code: { required: true, type: String,  },
            show_promos_popup: { required: true, type: Boolean, default: true, }
        },
        data(){
            return {
                visible: false,
            }
        },
        mounted() {
            this.$emitter.on('menu--promos-sidebar', () => {
                this.visible = true
            })
        },
        components: {
            Sidebar,
            PromoItem,
        }
    }
</script>