<template>
    <div class="mandp-product-card drink small" :class="{ 'black': dark_card }" @click="onWatchProductDetails">
        <div v-if="product.product_img !== ''" class="mandp-product-card--image">
            <img :src="product.product_img" alt="">
        </div>
        <div class="mandp-product-card--body">
            <p class="mandp-product-card--name">
                {{ product.name }}
            </p>
            <p class="mandp-product-card--price">
                $ {{ product.price }}
                <span v-if="product.weight" class="mandp-product-card--weight">
                    {{ product.weight }} {{ product.weight_type || '' }}
                </span>
            </p>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: { required: true, type: [ Object, Array ] },
        theme_code: { required: true, type: String },
        dark_card: { required: false, type: Boolean, default: false },
    },
    methods: {
        onWatchProductDetails(){
            this.$emitter.emit('mandp--product-details', {product: this.product, is_drink: true})
        }
    }
}
</script>