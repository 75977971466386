<template>
    <div class="mandp-big-beers-section">
        <div class="grid">
            <div class="col-4"></div>
            <div class="col-8" style="padding-right: 20px">
                <template v-if=" products.length > 0 ">
                    <h1 class="mandp-section-name red animate__animated" data-animation="animate__slideInDown">CAGUAMONES</h1>

                    <table class="mandp-beers-table">
                        <tr>
                            <th style="width: 60px"></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr v-if=" Heineken " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Logo-HeinekenColor.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Heineken
                                    <small>1000ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ Heineken?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" XXLager " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/xx-Lager-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    XX LAGER
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ XXLager?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" XXAmbar " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/xx-Ambar-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    XX AMBAR
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ XXAmbar?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" TecateO " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Tecate-O-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Tecate original
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ TecateO?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" TecateLight " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Tecate-L-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Tecate light
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ TecateLight?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" Indio " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Indio-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Indio
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ Indio?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" Sol " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/sol-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    SOL
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ Sol?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" CartaBlanca " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Carta-Blanca-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Carta blanca
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ CartaBlanca?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" Superior " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Superior-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Superior
                                    <small>1180ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ Superior?.price }}</p>
                            </td>
                        </tr>
                    </table>

                    <br>
                </template>

                <template v-if=" cproducts.length > 0 ">
                    <h1 class="mandp-section-name red animate__animated" data-animation="animate__slideInDown">CAGUAMAS</h1>
                    <table class="mandp-beers-table">
                        <tr>
                            <th style="width: 60px"></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>

                        <tr v-if=" TecateOC " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Tecate-O-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Tecate original
                                    <small>940ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ TecateOC?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" TecateLightC " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Tecate-L-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Tecate light
                                    <small>940ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ TecateLightC?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" SolC " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/sol-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    SOL
                                    <small>940ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ SolC?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" CartaBlancaC " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/Carta-Blanca-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Carta blanca
                                    <small>940ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ CartaBlancaC?.price }}</p>
                            </td>
                        </tr>
                        <tr v-if=" MillerHighLC " class="beer-list-item animate__animated" data-animation="animate__slideInLeft">
                            <td class="beer-list-item--logo">
                                <img class="beers-logo" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/miller-hight-life-logo.png" alt="">
                            </td>
                            <td colspan="2">
                                <p class="beer-name">
                                    Miller High Life
                                    <small>940ML</small>
                                </p>
                            </td>
                            <td>
                                <p class="beer-price">${{ MillerHighLC?.price }}</p>
                            </td>
                        </tr>
                    </table>

                    <br><br>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array, },
            cproducts: { required: true, type: Array }
        },
        computed: {
            Heineken(){
                return this.products.filter(p => p.name === 'Heineken')[0]
            },
            Sol(){
                return this.products.filter(p => p.name === 'Sol')[0]
            },
            XXLager(){
                return this.products.filter(p => p.name === 'Dos Equis')[0]
            },
            XXAmbar(){
                return this.products.filter(p => p.name === 'Dos Equis Ambar')[0]
            },
            TecateO(){
                return this.products.filter(p => p.name === 'Tecate')[0]
            },
            TecateLight(){
                return this.products.filter(p => p.name === 'Tecate Light')[0]
            },
            CartaBlanca(){
                return this.products.filter(p => p.name === 'Carta blanca')[0]
            },
            Superior(){
                return this.products.filter(p => p.name === 'Superior')[0]
            },
            Indio(){
                return this.products.filter(p => p.name === 'Indio')[0]
            },
            TecateOC(){
                return this.cproducts.filter(p => p.name === 'Tecate')[0]
            },
            TecateLightC(){
                return this.cproducts.filter(p => p.name === 'Tecate Light')[0]
            },
            SolC(){
                return this.cproducts.filter(p => p.name === 'Sol')[0]
            },
            CartaBlancaC(){
                return this.cproducts.filter(p => p.name === 'Carta blanca')[0]
            },
            MillerHighLC(){
                return this.cproducts.filter(p => p.name === 'Miller High Life')[0]
            }
        }
    }
</script>
