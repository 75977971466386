<template>
    <div :class="`mandp-menu-product ${theme_code} col-12 md:col-6 lg:col-4 xl:col-4`" class="">

        <div :class="`mandp-menu-product--item ${theme_code}`">
            <div :class="`mandp-menu-product--item--img ${theme_code}`" :style="{ 'background-image': product.product_img !==
                                        '' ?
                    `url(${product.product_img})` : '' }">
                <i v-if="product.product_img === ''" class='bx bx-image'></i>
            </div>
            <div class="mandp-menu-product--item--info">
                <div class="grid">
                    <div class="col">
                        <h4 :class="`mandp-menu-product--item--product-name ${theme_code}`">{{ product.name }}</h4>
                    </div>
                </div>
                <p :class="`mandp-menu-product--item--info--description ${theme_code}`">{{ product.description }}</p>
                <div class="grid">
                    <div class="col"></div>
                    <div class="col-fixed">
                        <p :class="`mandp-menu-product--item--price ${theme_code}`">
                            $ {{ product.price }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            theme_code: { required: true, type: String, },
            product: { required: true, type: [ Object, Array ] }
        }
    }
</script>