<template>
    <div class="container">
        <div class="cer-est-3-hamb-box">

            <div class="cer-est-3-hamb-box--title">
                <h1 class="mandp-animate-backInRight animate__animated">{{ section.name }}</h1>
            </div>

            <div class="cer-est-3-hamb-box--container">

                <div class="cer-est-3-hamb-box--img">
                    <img class="mandp-animate-backInRight animate__animated" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes/CER-EST-3/assets/cer-est-3-hamb.png" alt="">
                </div>

                <div class="cer-est-3-hamb-box--products">

                    <div class="cer-est-3-hamb-box--products--top"></div>
                    <div class="cer-est-3-hamb-box--products--center">

                        <div class="container">

                            <MenuProductFoodRender
                                :theme_code="theme_code"
                                :section="section"
                            />

                        </div>


                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>