<template>
    <div class="container">
        <div class="cer-est-3-entr-box">

            <div class="cer-est-3-entr-box--title">
                <h1 class="mandp-animate-backInRight animate__animated">{{ section.name }}</h1>
            </div>

            <div class="cer-est-3-entr-box--top"></div>
            <div class="cer-est-3-entr-box--center">
                <div class="container">

                    <MenuProductFoodRender
                        :theme_code="theme_code"
                        :section="section"
                    />

                </div>
            </div>
            <div class="cer-est-3-entr-box--bottom"></div>
            <center>
                <img class="cer-est-3-entr-box--bottom--img mandp-animate-backInRight animate__animated" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes/CER-EST-3/assets/cer-est-3-entr-bottom-img.png">
            </center>
        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>