<template>
    <p>URL Menu</p>
</template>

<script>
    export default {
        methods: {
            fetchMenuData(){
                window.axios.post(`${this.$global.API_URL}/get-menu-url/${this.$route.params.uid}`, {
                    'analytic_source': this.$route.query.utm_source || 'web',
                })
                    .then(response => {
                        if( response.data.success ){
                            window.location.replace( response.data.redirect_url )
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        if( error.response.status === 404 ) this.$router.replace('/404')
                    })
            }
        },
        mounted() {
            this.fetchMenuData()
        }
    }
</script>