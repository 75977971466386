<template>
    <div class="mandp-section-four--conteiner">
        <div class="mandp-section-four--header">
            <h1>{{ section.name }}</h1>
        </div>
        <div class="mandp-section-four--line"></div>

        <div class="container">

            <template v-if=" section.grid_type === 'list' ">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center mandp-two-column--img">
                        <img src="https://menuandpricing.fra1.digitaloceanspaces.com/themes%2Fskus%2Fcaguamas.png">
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <ProductDrink v-for="product in section.products"
                                      :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                    </div>
                </div>
            </template>

            <div v-else class=" mandp-section-four--box">

                <div class="row justify-content-center">

                    <div class="col-sm-12 col-md-12 col-lg-6 mandp-section-four--image">
                        <img src="https://menuandpricing.fra1.digitaloceanspaces.com/themes%2Fskus%2Fcaguamas.png">
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-6 justify-content-center align-items-center mandp-section-four--products">
                        <div class="row products-container justify-content-center">
                            <div class="product-card gx-4 gy-4 col-sm-12 col-md-6 col-lg-6" v-for="product in FirstFourProducts"
                                 :key="`menu_product_${product.id}`">
                                <ProductDrink :theme_code="theme_code" :product="product" />
                            </div>
                        </div>
                    </div>

                    <div v-if="NextProducts.length > 0" class="row inner justify-content-center">
                        <div class="gx-4 gy-4 col-sm-12 col-md-6 col-lg-3" v-for="product in NextProducts"
                             :key="`menu_product_${product.id}`">
                            <ProductDrink :theme_code="theme_code" :product="product" />
                        </div>
                    </div>



                </div>

            </div>

        </div>


    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        computed: {
            FirstFourProducts(){
                return this.section.products?.slice(0, 4)
            },
            NextProducts(){
                let products_length = this.section.products.length
                return this.section.products?.slice(4, products_length)
            }
        },
        components: {
            ProductDrink,
        }
    }
</script>