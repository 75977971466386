<template>
    <template v-if=" section.grid_type === 'list' ">
        <ProductFood v-for="product in section.products"
                     :key="`menu_product_${product.id}`"
                     :theme_code="theme_code"
                     :product="product"
        />
    </template>

    <div v-else class="row products-container justify-content-center">

        <div :class=" `product-card gx-4 gy-4 ${CardSizeClasses}` "
             v-for="product in section.products"
             :key="`menu_product_${product.id}`" >

            <ProductFood
                :theme_code="theme_code"
                :product="product"
                :dark_card="dark_card"
                :primary_theme_color="primary_theme_color"
            />

        </div>

    </div>
</template>

<script>
    import ProductFood from "@/components/General/Product-Food"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]},
            dark_card: { required: false, type: Boolean, default: false },
            primary_theme_color: { required: false, type: Boolean, default: false },
        },
        computed: {
            CardSizeClasses(){
                if( this.section.grid_type === 'small-card' ) return "col-sm-6 col-md-4 col-lg-3"
                else return "col-sm-12 col-md-6 col-lg-4"
            }
        },
        components: {
            ProductFood,
        }
    }
</script>