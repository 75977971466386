<template>
    <div class="mandp-loading">
        <img src="/imgs/mandp-beer.png" alt="">
        <h3 style="color: black" class="mandp-component">Cargando</h3>
    </div>
</template>

<script>
    export default {
        name: 'g-loading',
    }
</script>