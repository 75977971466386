<template>
    <p v-if="loading">Loading menu</p>

    <template v-else>

        <ConfirmDialog />

        <ProductDetails />

        <MenuSidebar :menu="menu" :sections="menu.sections" />

        <MenuPromos :menu="menu" :hnk_promos="hnk_promos" :theme_code="ThemeCode" :show_promos_popup="show_promos_popup" />

        <MenuHeader :menu="menu" :theme_code="ThemeCode" :cc="menu.cc" :has_promos="MenuHasPromos" />
        <!--<component :is="ThemeHeaderComponent" :theme_code="ThemeCode" :cc="menu.cc" />-->

        <MenuBody :theme_code="ThemeCode" :sections="menu.sections" />

        <MenuFooter :theme_code="ThemeCode" />

    </template>
</template>

<script>
    import MenuBody from "@/components/Menu/MenuBody"
    import MenuPromos from "@/components/Menu/MenuPromos"
    import MenuHeader from './Menu/MenuHeader'
    import MenuFooter from '@/components/General/MenuFooter'
    import MenuSidebar from "@/components/General/MenuSidebar"
    import ConfirmDialog from 'primevue/confirmdialog'
    import ProductDetails from "@/components/General/ProductDetails"
    /*import CER_EST_1_Header from '@/components/Themes/CER-EST-1/Header'
    import CER_EST_2_Header from '@/components/Themes/CER-EST-2/Header'
    import CER_PREM_1_Header from '@/components/Themes/CER-PREM-1/Header'
    import CER_PREM_2_Header from '@/components/Themes/CER-PREM-2/Header'
    import MAR_EST_1_Header from '@/components/Themes/MAR-EST-1/Header'
    import MAR_EST_2_Header from '@/components/Themes/MAR-EST-2/Header'
    import MAR_PREM_1_Header from '@/components/Themes/MAR-PREM-1/Header'
    import MAR_PREM_2_Header from '@/components/Themes/MAR-PREM-2/Header'
    import MED_1_Header from '@/components/Themes/MED-1/Header'*/

    export default {
        data(){
            return {
                menu: null,
                hnk_promos: [],
                loading: true,
                show_promos_popup: true,
            }
        },
        computed: {
            ThemeCode(){
                if( this.menu === null ) return null
                else return this.menu.theme.theme_code
            },
            MenuHasPromos(){
                let promos_count = 0
                promos_count += this.menu?.promotions?.length || 0
                promos_count += this.hnk_promos?.length || 0
                return promos_count > 0
                //return this.menu?.promotions?.length > 0 || false
            },
            ThemeHeaderComponent(){
                return `${this.ThemeCode.replaceAll('-', '_')}_Header`
            }
        },
        methods: {
            fetchMenuData(){
                window.axios.post(`${this.$global.API_URL}/get-menu/${this.$route.params.uid}`, {
                    'analytic_source': this.$route.query.utm_source || 'web',
                    'redirected' : this.$route.query.redirected || undefined,
                })
                    .then(response => {
                        if( response.data.success ){

                            if( response.data.redirect_url !== undefined ){
                                window.location.replace( response.data.redirect_url )
                            }
                            else {
                                this.menu = response.data.menu
                                this.hnk_promos = response.data.hnk_promotions
                                this.loading = false

                                // Inject theme assets
                                window.$('body').addClass(response.data.menu.theme.theme_code)
                                import(`@/assets/css/themes/${response.data.menu.theme.theme_code}/theme.scss`)

                                // Set page title to CC name
                                document.title = this.menu.cc.name

                                // Menu promos sidebar

                                let self = this
                                setTimeout(() => {
                                    self.$emitter.emit('mandp--global-loading', false)
                                    if( self.MenuHasPromos ) self.$emitter.emit('menu--promos-sidebar')
                                }, 1000)
                            }

                        }
                    })
                    .catch(error => {
                        console.log(error)
                        if( error.response.status === 404 ) this.$router.replace('/404')
                    })
            }
        },
        mounted() {
            this.fetchMenuData()

            if( this.$route.query.promo_popup ) this.show_promos_popup = (this.$route.query.promo_popup === 'true' )
        },
        components: {
            MenuBody,
            MenuPromos,
            MenuHeader,
            MenuFooter,
            MenuSidebar,
            ConfirmDialog,
            ProductDetails,
            /*CER_EST_1_Header,
            CER_EST_2_Header,
            CER_PREM_1_Header,
            CER_PREM_2_Header,
            MAR_EST_1_Header,
            MAR_EST_2_Header,
            MAR_PREM_1_Header,
            MAR_PREM_2_Header,
            MED_1_Header,*/
        }
    }
</script>