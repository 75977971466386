<template>
    <div :class="`mandp-footer mandp-component ${theme_code}`">
        <div class="container flex flex-column justify-content-center align-items-center">
            <!--<h1>Footer</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad aut debitis eos harum incidunt iusto magnam magni molestias
                officiis perferendis possimus recusandae, rem sed sequi sint sit temporibus velit.</p>
            <div class="mandp-footer--line"></div>-->
            <div class="mandp-footer--info">
                <div class="mandp-footer--info--logo">
                    <img src="/imgs/mandp-logo.png" alt="">
                </div>
                <!--<div class="mandp-footer--info--cta">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. <br>
                        Asperiores enim et eum expedita fugiat. </p>
                    <br>
                    <p><strong>Crear tu propio menú</strong></p>
                </div>-->
            </div>
            <a href="https://app.menuandpricing.com/terms" target="_blank" class="mandp-footer--url">Términos y Condiciones - Aviso de Privacidad</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            theme_code: { required: true, type: String },
        }
    }
</script>