<template>

    <!--<promo-01 />-->

    <component :is="promoComponent" />

</template>

<script>
    import Promo01 from "@/components/Promos/Promo01.vue"
    import Promo02 from "@/components/Promos/Promo02.vue"
    import Promo03 from "@/components/Promos/Promo03.vue"
    import Promo04 from "@/components/Promos/Promo04.vue"

    export default  {
        data(){
            return {
                promoComponent: 'promo-03',
            }
        },
        components: {
            'promo-01': Promo01,
            'promo-02': Promo02,
            'promo-03': Promo03,
            'promo-04': Promo04,
        },
        mounted() {
            this.promoComponent = this.$route.params.code
            this.$emitter.emit('mandp--global-loading', false)
        }
    }
</script>
