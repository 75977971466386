<template>
    <div class="mandp-pizz-2--entr">
        <div class="container">
            <h1>{{ section.name }}</h1>
            <div class="mandp--pizz--products">

                <MenuProductFoodRender
                    :theme_code="theme_code"
                    :section="section"
                />

            </div>
        </div>
    </div>
    <div class="mandp-pizz-2--entr--bottom"></div>
</template>

<script>
import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

export default {
    props: {
        theme_code: {required: true, type: String,},
        section: {required: true, type: [Object, Array,]}
    },
    components: {
        MenuProductFoodRender,
    }
}
</script>