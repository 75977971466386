import { createApp } from 'vue'
import App from './App.vue'

import router from "@/router";

import Loading from './components/GLoading'

import mitt from 'mitt'
const emitter = mitt()

import axios from 'axios'
window.axios = axios

/*import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery*/

import lodash from 'lodash'
window._ = lodash

/*import Masonry from 'masonry-layout'
window.Masonry = Masonry*/

// Animate.css
import('animate.css')

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App)

app.use(PrimeVue)
app.use(router)
app.use(ConfirmationService)

app.component('GLoading', Loading)

// Global properties
app.config.globalProperties.$global = {
    API_URL: process.env.VUE_APP_API_URL,
}
app.config.globalProperties.$emitter = emitter;

app.mount('#app')
