<template>
    <!-- MAR-EST-1 backgroud -->
    <img v-if="theme_code === 'MAR-EST-1'" class="mandp--background-img"
         src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMAR-EST-1%2Fassets%2Fmar-est-1-background-img.png" alt="">

    <!-- MAR-EST-2 backgroud -->
    <img v-if="theme_code === 'MAR-EST-2'" class="mandp--background-img"
          src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMAR-EST-2%2Fassets%2Fmar-est-2-background.png" alt="">

    <div :class="`mandp-menu-header ${theme_code} d-flex align-items-center justify-content-center`">
        <Button class="mandp-header--menu-button p-button-icon-only p-button-rounded p-button-text" icon="bx bx-menu"
                @click="onShowSectionsSidebar" />
        <p @click="onShowSectionsSidebar" class="mandp-header--menu-button--label">Menú</p>
        <div :class="`mandp-menu-header--${theme_code}-box`"  style="width: 100%">
            <div class="container">
                <div class="row">
                    <div :class="{ 'mar-prem-2--header-top': theme_code === 'MAR-PREM-2' }" class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column align-items-center justify-content-center">

                        <div class="logo-cc">
                            <img v-if="cc.logo_img" :class="`mandp-cc-logo ${theme_code}`" :src="cc.logo_img" alt="">
                            <p v-else>{{ CCNameLetters }}</p>
                        </div>
                        <h1 class="name-cc">{{ cc.name }}</h1>
                        <p v-if="menu.description" class="menu-description">{{ menu.description }}</p>

                        <div v-if="has_promos" class="cc-header--promo-cta">
                            <Button @click="onShowPromosSidebar" class="mandp-promos-button" icon="bx bxs-discount"
                                    label="Ver Promociones" />
                        </div>

                        <div class="cc-header--rrss">
                            <div v-if="cc.whatsapp !== null" @click="onGoToRS(`https://wa.me/521${cc.whatsapp}`)" class="cc-header--rrss--item">
                                <i class='bx bxl-whatsapp'></i>
                            </div>
                            <div v-if="cc.facebook !== null" @click="onGoToRS(cc.facebook)" class="cc-header--rrss--item">
                                <i class='bx bxl-facebook-circle' ></i>
                            </div>
                            <div v-if="cc.instagram !== null" @click="onGoToRS(cc.instagram)" class="cc-header--rrss--item">
                                <i class='bx bxl-instagram-alt' ></i>
                            </div>
                            <div v-if="cc.twitter !== null" @click="onGoToRS(cc.twitter)" class="cc-header--rrss--item">
                                <i class='bx bxl-twitter' ></i>
                            </div>
                            <div v-if="cc.youtube !== null" @click="onGoToRS(cc.youtube)" class="cc-header--rrss--item">
                                <i class='bx bxl-youtube' ></i>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MEX-1 -->
    <div v-if="theme_code === 'MEX-1'" class="mandp-mex-1-header-bottom"></div>
</template>

<script>
    import Button from "primevue/button"

    export default {
        name: 'MED_1_Header',
        props: {
            cc: { required: true, type: [ Object, Array ] },
            menu: {required: true, type: [Object, Array]},
            theme_code: { required: true, type: String, },
            has_promos: { required: true, type: Boolean, default: false, },
        },
        computed: {
            CCNameLetters(){
                if( this.cc.name ){
                    return this.cc.name.match(/\b(\w)/g).join('')
                }
                else return 'M&P'
            }
        },
        methods: {
            onShowSectionsSidebar(){
                this.$emitter.emit('menu--sections-sidebar')
            },
            onShowPromosSidebar(){
                console.log('show promos sidebar')
                this.$emitter.emit('menu--promos-sidebar')
            },
            onGoToRS(url){
                this.$confirm.require({
                    header: 'Aviso de enlace externo',
                    message: `Usted está saliendo de este sitio web`,
                    acceptLabel: 'Confirmar',
                    rejectLabel: 'Cancelar',
                    accept: () => {
                        return window.open(url, '_blank')
                    },
                    reject: () => { return false }
                })
            },
        },
        components: {
            Button,
        }
    }
</script>