<template>
    <div class="mandp-section-two">
        <div class="mandp-section-two-header">
            <h1>{{ section.name }}</h1>
        </div>
        <div class="mandp-section-two-image">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FMAR-EST-2%2Fassets%2Fmar-est-2-platf.png" alt="">
        </div>
        <div class="container mandp-section-two-products">

            <MenuProductFoodRender
                :theme_code="theme_code"
                :section="section"
            />

        </div>
    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>