<template>
    <div class="mandp-section-two">

        <div class="container">

            <div class="mandp-section-two--header--top-line"></div>

            <div class="mandp-section-two--header">
                <h1>{{ section.name }}</h1>
            </div>

            <div class="mandp-section-two--products">

                <template v-if=" section.grid_type === 'list' ">
                    <ProductDrink v-for="product in section.products"
                                  :key="`menu_product_${product.id}`" :theme_code="theme_code" :product="product" />
                </template>

                <div v-else class="products-container row justify-content-center">

                    <div class="product-card gx-4 gy-4 col-sm-6 col-md-4 col-lg-3" v-for="product in section.products"
                         :key="`menu_product_${product.id}`">
                        <ProductDrink :theme_code="theme_code" :product="product" />
                    </div>

                </div>

            </div>

            <div class="mandp-section-two--logos">
                <center><img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2Fskus%2Fcervezas-logos.png" alt=""></center>
            </div>

        </div>


    </div>
</template>

<script>
    import ProductDrink from "@/components/General/Product-Drink"

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object,Array,]}
        },
        components: {
            ProductDrink,
        }
    }
</script>