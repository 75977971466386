<template>
    <div class="mandp-section mandp-section-four-conteiner">
        <div class="mandp-section-three">
            <div class="mandp-three-dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            ({{ section.name }})
            <div class="mandp-three-dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>

        <div class="mandp-section-four--products">

            <div class="container">

                <MenuProductFoodRender
                    :theme_code="theme_code"
                    :section="section"
                    dark_card
                />

            </div>

        </div>

        <div class="mandp-section-four--image">
            <img src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/themes%2FCER-EST-2%2Fassets%2Fcer-est-2-platos-fuertes.png">
        </div>

    </div>
</template>

<script>
    import MenuProductFoodRender from "@/components/General/MenuProductFoodRender";

    export default {
        props: {
            theme_code: {required: true, type: String,},
            section: {required: true, type: [Object, Array,]}
        },
        components: {
            MenuProductFoodRender,
        }
    }
</script>