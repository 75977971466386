<template>
    <div :class="`mandp-menu-header`">
        <div class="container">
            <div class="row">
                <div class="flex flex-column align-items-center justify-content-center">
                    <div class="logo-cc animate__animated animate__delay-1s" data-animation="animate__slideInDown">
                        <img :class="`mandp-cc-logo`" :src="cc.logo_img" alt="">
                    </div>
                    <CCHeader :cc="cc" :has_promos="false" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import CCHeader from "./CCHeader.vue"

    export default {
        props: {
            cc: { required: true }
        },
        mounted() {
            // Animations
        },
        components: {
            CCHeader,
        }
    }
</script>
