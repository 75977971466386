<template>
    <g-loading v-if="loading" />
    <router-view />
</template>

<script>

export default {
  name: 'App',
    data(){
      return {
          loading: true,
      }
    },
    mounted() {
      this.$emitter.on('mandp--global-loading', show => this.loading = show )

        window.addEventListener('resize', function() {
            // initial scale on window resize
            if (document.documentElement.clientWidth < 767) {
                document.querySelector("meta[name=viewport]").setAttribute(
                    'content',
                    'width=device-width, initial-scale=0.8, maximum-scale=0.7, user-scalable=0');
            }
            else {
                document.querySelector("meta[name=viewport]").setAttribute(
                    'content',
                    'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
            }

        }, true);

        // inital scale on load
        if (document.documentElement.clientWidth < 767) {
            document.querySelector("meta[name=viewport]").setAttribute(
                'content',
                'width=device-width, initial-scale=0.8, maximum-scale=0.7, user-scalable=0');
        }
    }
}
</script>

<style lang="scss">
    @import "./assets/css/app.scss";
    @import "primevue/resources/themes/md-dark-indigo/theme.css";
    @import "primeicons/primeicons.css";
    @import "primeflex/primeflex.min.css";
</style>